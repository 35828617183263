// import i18n from '@/language/i18n'
import auth from '@/middleware/auth'

export default [
	// {
	// 	path: '/dashboard',
	// 	name: 'Dashboard',
	// 	icon: 'dashboard',
	// 	meta: {
	// 		title: 'Dashboard'
	// 	},
	// 	beforeEnter: auth,
	// 	component: () => import('@/pages/Dashboard')
	// },
	{
		path: '/roles/:page?/:id?',
		name: 'Roles',
		icon: 'roles',
		meta: {
			title: 'menu.roles'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Roles')
	},
	{
		path: '/users/:page?/:id?',
		name: 'Users',
		icon: 'users',
		meta: {
			title: 'menu.users'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Users')
	},
	{
		path: '/customer/:page?/:id?',
		name: 'Customers',
		icon: 'account-group',
		meta: {
			title: 'menu.customers'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Customers')
	},
	{
		path: '/types/:page?/:id?',
		name: 'Types',
		icon: 'alpha-t',
		meta: {
			title: 'menu.types'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Types')
	},
	{
		path: '/categories/:page?/:id?',
		name: 'Categories',
		icon: 'alpha-c',
		meta: {
			title: 'menu.categories'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Categories')
	},
	{
		path: '/attributes/:page?/:id?',
		name: 'Attributes',
		icon: 'alpha-a',
		meta: {
			title: 'menu.attributes'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Attributes')
	},
	{
		path: '/variations/:page?/:id?',
		name: 'Variations',
		icon: 'alpha-v',
		meta: {
			title: 'menu.variations'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Variations')
	},
	{
		path: '/products/:page?/:id?',
		name: 'Products',
		icon: 'product',
		meta: {
			title: 'menu.products'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Products'),
		// children: [
		// 	{
		// 		path: 'attributes',
		// 		name: 'Attributes',
		// 		icon: 'alpha-a',
		// 		meta: {
		// 			title: 'menu.attributess'
		// 		},
		// 		beforeEnter: auth,
		// 		component: () => import('@/pages/Attributes')
		// 	}
		// ]
	},
	{
		path: '/links',
		name: 'Links',
		icon: 'links',
		meta: {
			title: 'Links'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Links')
	},
	/* auto-gerado */
	{
		path: '/settings',
		name: 'Settings',
		icon: 'settings',
		meta: {
			title: 'menu.settings'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Settings')
	}
]
